<template>
  <div class="home-search">
    <search-form :keywords="keywords" />
    <div class="home-search-box">
      <div class="home-search-content">
        <template v-if="list.length">
          <search-result-card
            v-for="item in list"
            :key="item.id"
            :tag="
              item.searchResultType === '1'
                ? item.hotType == 2 || item.hotType == 3
                  ? '鸿蒙方案'
                  : '数字化转型方案'
                : searchResultTypeMap[item.searchResultType].name
            "
            :title="item.name"
            :expand.sync="item.tagExpand"
            @link="searchResultTypeMap[item.searchResultType].navigation(item)"
          >
            <template #extra>
              <p
                v-show="
                  item.hotType != 1 &&
                  item.hotType != 4 &&
                  item.hotType != 5 &&
                  item.hotType != 6
                "
                @click="
                  openGraph(
                    item.id,
                    searchResultTypeMap[item.searchResultType].catrgory,
                    item.name
                  )
                "
              >
                <a-icon type="deployment-unit" />
                关系图谱
              </p>
            </template>
            <!--解决方案-->
            <template v-if="item.searchResultType === '1'">
              <div class="company">
                <div>提供商</div>
                <div>
                  <template v-if="item.datSolutionIsvs != ''">
                    <p
                      v-for="(el, i) in item.datSolutionIsvs"
                      :class="{ active: merchantShow && merchantId == el.id }"
                      :key="i"
                      @click="merchantInfo(el.id, el.datIsv)"
                    >
                      <img alt="图片" src="@/assets/img/xinpianCPU.png" />{{
                        el.isvName
                      }}
                    </p>
                  </template>
                  <template v-else>/</template>
                </div>
              </div>
              <div>
                <span>IoT产品</span>
                <p
                  v-for="(el, i) in item.datProductList"
                  :key="i"
                  @click="$router.push(`/replace/info?id=${el.id}`)"
                >
                  {{ el.name }}
                </p>
              </div>
              <div>
                <span>场景标签</span>
                <div>{{ item.tag }}</div>
              </div>
            </template>
            <!--iot产品-->
            <template v-else-if="item.searchResultType === '2'">
              <div>
                <span>厂商</span>
                <p
                  :class="{ active: merchantShow && merchantId == item.id }"
                  @click="merchantInfo(item.id, item.datIsv)"
                >
                  <img alt="图片" src="@/assets/img/changshang.png" />
                  <span v-if="item.unhmState === '01'">【迁移沟通中】</span>
                  <span v-if="item.unhmState === '02'">【产品研发中】</span>
                  <span v-if="item.unhmState === '03'">【版本测试中】</span>
                  {{ item.pdtCorpName || "/" }}
                </p>
              </div>
              <div>
                <span>型号</span>
                <div>{{ item.brandNum || "/" }}</div>
              </div>
              <div>
                <span>SOC</span>
                <p
                  v-for="i in item.datSocList"
                  :key="i.id"
                  :class="{
                    active: chipShow && item.id == chipId && socId == i.id,
                  }"
                  @click="chipInfo(item.id, i, i.id)"
                >
                  <img alt="图片" src="@/assets/img/xinpianCPU.png" />{{
                    i.name
                  }}
                </p>
              </div>
              <div>
                <span>开发板</span>
                <p
                  :class="{ active: devShow && item.id == devInfoId }"
                  @click="devInfo(item.id, item.datDevboard)"
                >
                  <img alt="图片" src="@/assets/img/xinpianCPU.png" />{{
                    item.devboardName
                  }}
                </p>
              </div>
              <div>
                <span>嵌入式OS</span>
                <p
                  v-for="i in item.datOsList"
                  :key="i.id"
                  :class="{
                    active: embeddedShow && item.id == osInfoId && osId == i.id,
                  }"
                  @click="embeddedInfo(item.id, i, i.id)"
                >
                  <img alt="图片" src="@/assets/img/xinpianCPU.png" />{{
                    i.name
                  }}
                </p>
              </div>
              <div>
                <span>应用场景</span>
                <div>{{ item.sceneTagName }}</div>
              </div>
            </template>
            <!--开发板-->
            <template v-else-if="item.searchResultType === '3'">
              <div>
                <span>厂商</span>
                <p
                  v-for="(el, i) in item.datIsvs"
                  :key="i"
                  :class="{ active: merchantShow && merchantId == el.id }"
                  @click="merchantInfo(el.id, el)"
                >
                  <img alt="图片" src="@/assets/img/changshang.png" />
                  {{ el.name || "暂不显示" }}
                </p>
              </div>
              <div>
                <span>型号</span>
                <div>{{ item.modelNum || "/" }}</div>
              </div>
              <div>
                <span>SOC</span>
                <template v-if="item.datSocs">
                  <p
                    v-for="i in item.datSocs"
                    :key="i.id"
                    :class="{
                      active: chipShow && item.id == chipId && socId == i.id,
                    }"
                    @click="chipInfo(item.id, i, i.id)"
                  >
                    <img alt="图片" src="@/assets/img/xinpianCPU.png" />{{
                      i.name
                    }}
                  </p>
                </template>
                <template v-else>/</template>
              </div>
              <div>
                <span>嵌入式OS</span>
                <p
                  v-for="i in item.datOs"
                  :key="i.id"
                  :class="{
                    active: embeddedShow && item.id == osInfoId && osId == i.id,
                  }"
                  @click="embeddedInfo(item.id, i, i.id)"
                >
                  <img alt="图片" src="@/assets/img/xinpianCPU.png" />{{
                    i.name
                  }}
                </p>
              </div>
              <div>
                <span>应用场景</span>
                <div>{{ item.sceneTagName }}</div>
              </div>
            </template>
            <!--SOC-->
            <template v-else-if="item.searchResultType === '4'">
              <div>
                <span>厂商</span>
                <p
                  :class="{ active: merchantShow && merchantId == item.id }"
                  @click="merchantInfo(item.id, item.datIsv)"
                >
                  <img alt="图片" src="@/assets/img/changshang.png" />
                  {{ item.socCorpName }}
                </p>
              </div>
              <div>
                <span>型号</span>
                <div>{{ item.modelNum }}</div>
              </div>
              <div>
                <span>系列</span>
                <div>{{ item.setNum || '/'}}</div>
              </div>
              <div>
                <span>应用场景</span>
                <div>{{ item.sceneTagName }}</div>
              </div>
            </template>
            <!--嵌入式OS-->
            <template v-else-if="item.searchResultType === '5'">
              <div>
                <span>厂商</span>
                <p
                  :class="{ active: merchantShow && merchantId == item.id }"
                  @click="merchantInfo(item.id, item.datIsv)"
                >
                  <img alt="图片" src="@/assets/img/changshang.png" />
                  {{ item.osCorpName }}
                </p>
              </div>
              <div>
                <span>系统板本</span>
                <div>{{ item.sysVersion }}</div>
              </div>
              <div>
                <span>适配SOC</span>
                <div>{{ item.socMapping }}</div>
              </div>
              <div>
                <span>应用场景</span>
                <div>{{ item.sceneTagName }}</div>
              </div>
            </template>
          </search-result-card>
        </template>
        <div
          style="background: #fff; margin-bottom: 24px; padding: 40px 0"
          v-if="!list.length && !spinShow"
        >
          <a-empty />
        </div>

        <div
          class="home-spin"
          style="background: #fff; margin-bottom: 24px; padding: 85px 0"
          v-if="!list.length && spinShow"
        >
          <a-spin tip="加载中"> </a-spin>
        </div>

        <search-knowledge :key="keywords" :keywords="keywords" />
      </div>
      <a-anchor wrapper-class="home-search-anchor" class="anchor-box">
        <div class="home-search-right">
          <!-- 关系图谱 -->
          <relation ref="graphRef" />
          <!-- 厂商 -->
          <div class="home-search-merchant" v-if="merchantShow">
            <p>
              <span>厂商</span>
              <a-icon type="close" @click="merchantShow = false" />
            </p>
            <div>
              <div>
                <span
                  :class="{
                    'enterprise-color1': randNum == 1,
                    'enterprise-color2': randNum == 2,
                    'enterprise-color3': randNum == 3,
                    'enterprise-color4': randNum == 4,
                  }"
                >
                  <v-img
                    v-if="enterpriseInfo.isvImg"
                    :attachment-id="enterpriseInfo.isvImg"
                    :default-img="require('@/assets/img/mrlogo.png')"
                  />
                  <span v-else>{{ enterpriseInfo.name.substr(0, 1) }}</span>
                </span>
                <div>
                  <span>{{ enterpriseInfo.name }}</span>
                </div>
              </div>
              <p><span>联系电话：</span>{{ enterpriseInfo.linkPhone }}</p>
              <p><span>企业地址：</span>{{ enterpriseInfo.address }}</p>
            </div>
          </div>
          <!-- SOC -->
          <div class="home-search-chip" v-if="chipShow">
            <p class="search-detail-title">
              <span>SOC</span>
              <a-icon type="close" @click="chipShow = false" />
            </p>
            <div>
              <p>名称：{{ socInfo.name }}</p>
              <p>系列：{{ socInfo.setNum || '/'}}</p>
              <p>型号：{{ socInfo.modelNum }}</p>
              <p>
                场景：<span>{{ socInfo.sceneTagName }}</span>
              </p>
              <span
                @click="
                  $router.push(`/replace/productInfo/soc?id=${socInfo.id}`)
                "
                >查看详细参数 ></span
              >
            </div>
          </div>
          <!-- 开发板 -->
          <div class="home-search-chip" v-if="devShow">
            <p class="search-detail-title">
              <span>开发板</span>
              <a-icon type="close" @click="devShow = false" />
            </p>
            <div>
              <p>名称：{{ devInfoData.name }}</p>
              <p>型号：{{ devInfoData.modelNum }}</p>
              <p>
                SOC：
                <template v-for="item in devInfoData.datSocs"
                  >{{ item.name }}
                </template>
              </p>
              <p>
                OS：
                <template v-for="item in devInfoData.datOs"
                  >{{ item.name }}
                </template>
              </p>
              <p>场景：{{ devInfoData.sceneTagName }}</p>
              <span
                @click="
                  $router.push(`/replace/productInfo/dev?id=${devInfoData.id}`)
                "
                >查看详细参数 ></span
              >
            </div>
          </div>
          <!-- 嵌入式 -->
          <div class="home-search-chip" v-if="embeddedShow">
            <p class="search-detail-title">
              <span>嵌入式OS</span>
              <a-icon type="close" @click="embeddedShow = false" />
            </p>
            <div>
              <p>名称：{{ osInfo.name }}</p>
              <p>版本：{{ osInfo.sysVersion }}</p>
              <p>适配SOC：{{ osInfo.socMapping }}</p>
              <p>场景：{{ osInfo.sceneTagName }}</p>
              <span
                @click="$router.push(`/replace/productInfo/os?id=${osInfo.id}`)"
                >查看详细参数 ></span
              >
            </div>
          </div>

          <span @click="consult"
            ><img alt="图片" src="@/assets/img/ssgg.png"
          /></span>
        </div>
      </a-anchor>
    </div>
  </div>
</template>

<script>
import { getSearchInfo } from "@/api/iot";
import relation from "./relation.vue";
import { mapGetters } from "vuex";
import SearchKnowledge from "./modules/SearchKnowledge";
import SearchForm from "./modules/SearchForm";
import SearchResultCard from "./components/SearchResultCard";
import VImg from "@/components/VImg";
export default {
  components: {
    SearchResultCard,
    SearchForm,
    SearchKnowledge,
    relation,
    VImg,
  },
  data() {
    return {
      merchantShow: false,
      chipShow: false,
      devShow: false,
      embeddedShow: false,
      spinShow: true,
      infoPage: {
        current: 1,
        size: 6,
      },
      enterpriseInfo: "", //公司信息
      socInfo: "", //SOC信息
      devInfoData: "", //开发板信息
      osInfo: "", //os信息
      chipId: "",
      devInfoId: "",
      osInfoId: "",
      socId: "",
      osId: "",
      merchantId: "",
      searchData: [],
      list: [],
      categories: ["IoT产品", "OS", "开发板", "SOC", "解决方案", "厂商"],
      searchResultTypeMap: {
        1: {
          name: "解决方案",
          navigation: (item) => {
            let type = item.hotType;
            if (type == 2 || type == 3) {
              this.$router.push(`/solution/info?id=${item.id}`);
            } else if (type == 1) {
              this.$router.push(`/solution/internet_things?id=${item.id}`);
            } else if (type == 4 || type == 5) {
              this.$router.push(`/solution/ordinary_program?id=${item.id}`);
            } else if (type == 6) {
              this.$router.push(`/solution/digitizing?id=${item.id}`);
            }
          },
          catrgory: 4,
        },
        2: {
          name: "IoT产品",
          navigation: (item) => {
            this.$router.push(`/replace/info?id=${item.id}`);
          },
          catrgory: 0,
        },
        3: {
          name: "开发板",
          navigation: (item) => {
            this.$router.push(`/replace/productInfo/dev?id=${item.id}`);
          },
          catrgory: 2,
        },
        4: {
          name: "SOC",
          navigation: (item) => {
            this.$router.push(`/replace/productInfo/soc?id=${item.id}`);
          },
          catrgory: 3,
        },
        5: {
          name: "嵌入式OS",
          navigation: (item) => {
            this.$router.push(`/replace/productInfo/os?id=${item.id}`);
          },
          catrgory: 1,
        },
      },
      randNum: 0,
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
    keywords() {
      return this.$route.query.val;
    },
  },
  mounted() {
    if (localStorage.getItem("searchData")) {
      this.searchData = JSON.parse(localStorage.getItem("searchData"));
    }
    this.loadSearchResult();
    this.randNumFun();
  },
  methods: {
    randNumFun() {
      this.randNum = Math.floor(Math.random() * 4) + 1;
    },
    consult() {
      window.open(`/account/userLayout/userScenes/add`, "_blank");
    },
    loadSearchResult() {
      let { infoPage, keywords, searchData } = this;
      let data = {
        name: keywords,
      };
      getSearchInfo(infoPage, data).then((res) => {
        let isAdd = true;
        if (searchData != "") {
          searchData.forEach((element) => {
            if (element.title == keywords) {
              isAdd = false;
            }
          });
        }
        if (isAdd) {
          let obj = {
            title: keywords,
          };
          this.searchData.unshift(obj);
        }
        this.searchData.splice(3);
        let data = JSON.stringify(this.searchData);
        localStorage.setItem("searchData", data);

        const {
          datProductList,
          datSocList,
          datDevboardList,
          datOsList,
          datSolutionList,
        } = res;
        const list = [
          ...datSolutionList.records.map((e) => {
            return {
              searchResultType: "1",
              tagExpand: false,
              ...e,
            };
          }),
          ...datProductList.records.map((e) => {
            return {
              searchResultType: "2",
              tagExpand: false,
              ...e,
            };
          }),
          ...datDevboardList.records.map((e) => {
            return {
              searchResultType: "3",
              tagExpand: false,
              ...e,
            };
          }),
          ...datSocList.records.map((e) => {
            return {
              searchResultType: "4",
              tagExpand: false,
              ...e,
            };
          }),
          ...datOsList.records.map((e) => {
            return {
              searchResultType: "5",
              tagExpand: false,
              ...e,
            };
          }),
        ];
        list.length && (list[0].tagExpand = true);
        for (let index = 0; index < list.length; index++) {
          let obj = [];
          if (list[index].searchResultType == "1") {
            for (let i = 0; i < list[index].datSolutionFrameWorks.length; i++) {
              if (list[index].datSolutionFrameWorks[i].datProductList) {
                obj = [
                  ...obj,
                  ...list[index].datSolutionFrameWorks[i].datProductList,
                ];
              }
            }
            list[index].datProductList = obj;
          }
        }
        this.list = list;
        this.spinShow = false;
      });
    },
    openGraph(id, category, title) {
      this.$refs.graphRef.open(id, category, title);
    },
    merchantInfo(iotId, val) {
      console.log(val);
      if (iotId != this.merchantId) {
        this.merchantShow = true;
      } else {
        this.merchantShow = !this.merchantShow;
      }
      this.enterpriseInfo = val;
      this.merchantId = iotId;
    },
    chipInfo(id, val, socId) {
      if (socId != this.socId) {
        this.chipShow = true;
      } else {
        this.chipShow = !this.chipShow;
      }
      this.socInfo = val;
      this.chipId = id;
      this.socId = socId;
    },
    devInfo(id, val) {
      if (id != this.devInfoId) {
        this.devShow = true;
      } else {
        this.devShow = !this.devShow;
      }
      this.devInfoData = val;
      this.devInfoId = id;
    },
    embeddedInfo(id, val, osId) {
      if (osId != this.osId) {
        this.embeddedShow = true;
      } else {
        this.embeddedShow = !this.embeddedShow;
      }
      this.osInfo = val;
      this.osInfoId = id;
      this.osId = osId;
    },
  },
  watch: {
    keywords() {
      this.merchantShow = false;
      this.chipShow = false;
      this.devShow = false;
      this.embeddedShow = false;
      this.loadSearchResult();
    },
  },
};
</script>

<style lang="scss" scoped>
.home-search {
  .search-detail-title {
    padding: 0 16px;
    height: 56px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    > i {
      cursor: pointer;
    }
  }

  .home-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep {
      .ant-spin-text {
        color: #999999;
      }
      .ant-spin-dot-item {
        background-color: #999999;
      }
    }
  }

  .home-search-box {
    width: 1280px;
    margin: 0 auto;
    padding: 24px 0 56px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .home-search-content {
      width: 880px;

      .company {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > :nth-child(1) {
          width: 66px;
          color: #8c8c8c;
          margin-right: 32px;
        }
        :nth-child(2) {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          > p {
            margin-right: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 10px;
            border: 1px solid #dddddd;
            box-sizing: border-box;
            margin-bottom: 5px;
            > img {
              display: block;
              width: 16px;
              height: 16px;
              margin-right: 3px;
            }
          }
        }
      }
    }

    .anchor-box::v-deep {
      .ant-affix {
        position: relative !important;
      }
    }

    .home-search-right {
      .home-search-merchant {
        background: #fff;
        margin-bottom: 24px;

        > p {
          padding: 0 16px;
          height: 56px;
          border-bottom: 1px solid #f0f0f0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;

          > i {
            cursor: pointer;
          }
        }

        > div {
          padding: 16px;

          > div {
            display: flex;
            margin-bottom: 20px;
            align-items: center;
            > span {
              width: 56px;
              height: 56px;
              border: 1px solid #f0f0f0;
              margin-right: 16px;
              padding: 8px 4px;

              > img {
                width: 100%;
                height: 100%;
              }
              > span {
                display: flex;
                width: 100%;
                height: 100%;
                font-size: 32px;
                align-items: center;
                justify-content: center;
              }
            }

            > div {
              flex: 1;

              > span {
                line-height: 16px;
                font-size: 16px;
                display: block;
              }

              > p {
                display: flex;

                > span {
                  height: 24px;
                  background: #f5f5f5;
                  border-radius: 2px;
                  padding: 0 8px;
                  margin-right: 8px;
                  line-height: 24px;
                }
              }
            }
          }

          > span {
            display: block;
            line-height: 20px;
            text-align: justify;
            margin-bottom: 20px;
          }

          > p {
            display: flex;
            line-height: 20px;
            margin-bottom: 12px;
            &:last-child {
              margin-bottom: 0;
            }
            > span {
              color: #8c8c8c;
              white-space: nowrap;
            }
          }
        }
      }

      .home-search-chip {
        background: #fff;
        margin-bottom: 24px;

        > div {
          padding: 24px 16px 0;

          > p {
            line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 24px;
          }

          > span {
            display: block;
            height: 40px;
            cursor: pointer;
            line-height: 40px;
            text-align: center;
            color: #ea0b06;
            border-top: 1px solid #f0f0f0;
          }
        }
      }
      > span {
        display: block;
        > img {
          max-width: 100%;
        }
      }
    }
  }
}
.merchant-name {
  padding: 0 !important;
  border: 0 !important;
  cursor: initial !important;
}
</style>
<style>
.home-search-input input {
  font-size: 14px;
}
.home-search-anchor {
  padding: 0;
  margin: 0;
  background-color: transparent;
  width: 376px;
}
.home-search-anchor .ant-anchor {
  padding: 0;
}
.home-search-anchor .ant-anchor-ink {
  display: none;
}
</style>
