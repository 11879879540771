<template>
  <a-modal
    v-model="visible"
    :title="`${title} / 关系图谱`"
    width="800px"
    @cancel="visible = false"
    wrap-class-name="cancel-info-modal"
  >
    <div style="height: 500px">
      <e-chart
        :options="options"
        style="width: 750px; height: 500px"
        @chart-click="handleClick"
      />
    </div>
    <template #footer>
      <a-button @click="visible = false">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { getGraphData } from "@/api/iot";
import EChart from "@/components/EChart";

export default {
  components: { EChart },
  data() {
    return {
      visible: false,
      title: "",
      nodes: [],
      links: [],
      categories: [],
      loadingIds: [],
    };
  },
  computed: {
    options() {
      const { nodes, links, categories, loadingIds } = this;
      return {
        legend: {
          data: categories,
        },
        color: [
          "#1a92ff",
          "#c281ff",
          "#f6a133",
          "#82d3f8",
          "#67c59e",
          "#8502ff",
        ],
        series: [
          {
            type: "graph",
            layout: "force",
            nodes: nodes.map((e) => {
              return {
                ...e,
                symbolSize: loadingIds.indexOf(e.id) !== -1 ? 30 : 14,
              };
            }),
            links: links,
            categories: categories.map((e) => {
              return {
                name: e,
              };
            }),
            roam: true,
            label: {
              show: true,
              position: "inside",
            },
            force: {
              repulsion: 100,
              gravity: 0.01,
              edgeLength: 100,
            },
            lineStyle: {
              color: "source",
            },
          },
        ],
      };
    },
  },
  methods: {
    open(id, category, title) {
      this.visible = true;
      this.title = title;
      this.loadData(id, category, false);
    },
    loadData(id, category, append) {
      if (append && this.loadingIds.indexOf(id) !== -1) {
        console.log(`${id}已加载`);
        return;
      }
      if (!append) {
        this.nodes = [];
        this.links = [];
        this.categories = [];
      }
      getGraphData(id, category).then((data) => {
        const { nodes, links, categories } = data;
        if (!append) {
          this.nodes = nodes;
          this.links = links;
          this.categories = categories;
          this.loadingIds = [id];
        } else {
          this.loadingIds.push(id);
          const nodeIds = this.nodes.map((e) => e.id);
          nodes.forEach((e) => {
            if (nodeIds.indexOf(e.id) === -1) {
              this.nodes.push(e);
            }
          });
          links.forEach((e) => {
            if (
              !this.links.filter(
                (link) => link.source === e.source && link.target === e.target
              ).length
            ) {
              this.links.push(e);
            }
          });
        }
      });
    },
    handleClick(params) {
      const { id, category } = params.data;
      this.loadData(id, category, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-relation {
  background: #fff;
  margin-bottom: 24px;

  > p {
    padding: 0 16px;
    height: 56px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    > i {
      cursor: pointer;
    }
  }

  > div {
    height: 344px;
  }
}
</style>
