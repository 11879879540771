<template>
  <div class="home-search-info-box">
    <div class="home-search-info-title">
      <div>
        <span>{{ tag }}</span>
        <p @click="$emit('link')">
          {{ title }}
        </p>
      </div>
      <slot v-if="expand" name="extra" />
      <template v-if="tag != '数字化转型方案'">
        <span v-if="!expand" @click="$emit('update:expand', true)"
          >展开<a-icon type="down"
        /></span>
      </template>
    </div>
    <div class="home-search-info" v-if="expand && tag != '数字化转型方案'">
      <slot />
      <p>
        <span @click="$emit('update:expand', false)"
          >收起<a-icon type="up"
        /></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchResultCard",
  props: {
    tag: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    expand: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.home-search-info-box {
  background: #fff;
  margin-bottom: 24px;
  .home-search-info-title {
    padding: 0 24px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      flex: 1;
      > span {
        height: 24px;
        background: #f5f5f5;
        border-radius: 2px;
        padding: 0 8px;
        line-height: 24px;
        font-size: 12px;
        margin-right: 16px;
      }
      > p {
        flex: 1;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        line-height: 16px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          color: #EA0B06;
        }
      }
    }
    > p {
      height: 32px;
      border: 1px solid #dddddd;
      border-radius: 2px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      > i {
        margin-right: 8px;
      }
      &.active {
        background: #f8ebeb;
        color: #EA0B06;
        border-color: #EA0B06;
      }
    }
    > span {
      color: #EA0B06;
      cursor: pointer;
      display: flex;
      align-items: center;
      > i {
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
  .home-search-info {
    padding: 24px 24px 0;
    border-top: 1px solid #f0f0f0;
    > div {
      display: flex;
      margin-bottom: 16px;
      > span {
        line-height: 32px;
        width: 66px;
        color: #8c8c8c;
        margin-right: 32px;
        white-space: nowrap;
      }
      > p {
        height: 32px;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 6px;
        &:last-child{
          margin-right: 0;
        }
        > img {
          margin-right: 8px;
        }
        > span {
          color: #EA0B06;
        }
        &.active {
          background: #f8ebeb;
          color: #EA0B06;
          border-color: #EA0B06;
        }
      }
      > div {
        line-height: 32px;
      }
    }
    > p {
      border-top: 1px solid #f0f0f0;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      > span {
        color: #EA0B06;
        cursor: pointer;
        > i {
          margin-left: 4px;
        }
      }
    }
  }
}
</style>
